import React, { useContext, useState, useEffect } from 'react';
import PortfolioContext from '../../context/context';
import { Modal, Button } from 'react-bootstrap';

import resumeFile from './Kichul_Kang_Resume.pdf';

const Resume = () => {
  const { about } = useContext(PortfolioContext);
  const { resume } = about;

  console.log(resume);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const zoom = isDesktop ? '79' : '50';

  return (
    <>
      <span className="d-flex mt-3">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="cta-btn cta-btn--resume"
          onClick={handleShow}
        >
          Resume
        </a>
      </span>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Body>
          <iframe src={resumeFile.concat('#zoom=').concat(zoom)} width="1120" height="900" scrolling="no"/> 
          {/* {resumeFile+'#zoom=FitH'} */}
        </Modal.Body>
        <Modal.Footer>
          <span className="d-flex mt-3">
            <button
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              onClick={handleClose}
            >
              Close
            </button>
          </span>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Resume;
